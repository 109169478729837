
import { Options, Vue } from "vue-class-component";
import Input from "@/app/ui/components/input/index.vue";
import Toggle from "@/app/ui/components/toggle/index.vue";
import dateToTime from "@/app/infrastructures/misc/common-library/DateToTime";
import ellipsisString from "@/app/infrastructures/misc/common-library/EllipsisString";
import dateToDateString from "@/app/infrastructures/misc/common-library/DateToDateString";
import firstCapitalize from "@/app/infrastructures/misc/common-library/FirstCapitalize";
import { BaseRateController } from "@/app/ui/controllers/BaseRateController";
import { RatesDetail } from "@/domain/entities/BaseRates";

@Options({
  components: {
    Input,
    Toggle
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSuccess) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      this.onCloseSuccess();
      next();
    }
  }
})
export default class Edit extends Vue {
  goBack() {
    this.$router.push(`/tarif/base-rate/${this.id}`);
  }

  get id(): number {
    return Number(this.$route.params.id);
  }

  // Get Data
  created() {
    this.getDetail();
  }

  // Detail Data
  getDetail() {
    BaseRateController._onGetDetail(this.id);
  }
  get detailData(): RatesDetail {
    return BaseRateController.ratesDetail;
  }

  // Loading
  get isLoading() {
    return BaseRateController.isLoading;
  }

  // Handle Error
  get isError() {
    return BaseRateController.isError;
  }
  get errorCause() {
    return BaseRateController.isErrorCause;
  }
  handleErrorClose() {
    BaseRateController.setErrorEdit(false);
  }
  get isErrorEdit() {
    return BaseRateController.isErrorEdit;
  }

  // Modal Success
  get openSuccess() {
    return BaseRateController.openSuccess;
  }
  onCloseSuccess() {
    BaseRateController.setOpenSuccess(false);
  }

  // Save data
  onOpenSave() {
    return BaseRateController.setOpenModal(true);
  }
  get openSave() {
    return BaseRateController.openModal;
  }
  onCloseSave() {
    BaseRateController.setOpenModal(false);
  }

  _onEdit() {
    BaseRateController._onEdit({
      id: this.id,
      name: this.detailData.rateVersionName,
      status: this.isRatesStatus ? "for-sales" : "draft"
    });
  }

  // Date
  invalidStartDate = false;
  invalidEndDate = false;
  get periodeStart(): any {
    return BaseRateController.convertPeriodeStart;
  }
  get periodeEnd(): any {
    return BaseRateController.convertPeriodeEnd;
  }

  validateDateStartEnd() {
    const dateStart = new Date(this.periodeStart);
    const dateEnd = new Date(this.periodeEnd);
    this.invalidStartDate =
      dateStart.getTime() > dateEnd.getTime() ||
      dateStart.getTime() === dateEnd.getTime();
    this.invalidEndDate =
      dateEnd.getTime() < dateStart.getTime() ||
      dateEnd.getTime() === dateStart.getTime();
  }
  inputDateStart(param: Date) {
    BaseRateController.setPeriodeStart(param);
    this.validateDateStartEnd();
  }

  inputDateEnd(param: Date) {
    BaseRateController.setPeriodeEnd(param);
    this.validateDateStartEnd();
  }

  // Close when changed
  isEditTerminated = false;
  newPath = "";
  answer = false;

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  // Ellipsis String
  ellipsisString(text: string) {
    return ellipsisString(text, 50);
  }

  onDelete() {
    alert("Delete is maintenance");
  }

  // Format Date
  formatDate(date: string) {
    return `${dateToDateString(new Date(date))} ${dateToTime(
      new Date(date)
    )} WIB`;
  }

  // Toggle Rates Status
  openRatesStatus = false;
  onOpenRatesStatus(value: boolean) {
    this.openRatesStatus = value;
  }
  get isRatesStatus() {
    return BaseRateController.isRatesStatus;
  }
  get isCacheRatesStatus() {
    return BaseRateController.isCacheRatesStatus;
  }
  changeRatesStatus() {
    BaseRateController.setRatesStatus(!this.isRatesStatus);
    this.openRatesStatus = false;
  }

  // disable
  get isDisabled() {
    return this.invalidEndDate || this.invalidStartDate;
  }

  // Capitalize
  capitalize(text: string) {
    return firstCapitalize(text);
  }
}
