
import { Options, Vue } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import TableV1 from "@/app/ui/components/table/index.vue";
import {
  checkRolePermission,
  dateToDateString,
  ellipsisString,
  formatDate
} from "@/app/infrastructures/misc/Utils";
import { PRICING_BASE_RATE } from "@/app/infrastructures/misc/RolePermission";
import { BaseRateController } from "@/app/ui/controllers/BaseRateController";
import { RatesDetail, UpdateHistory } from "@/domain/entities/BaseRates";

@Options({
  components: {
    DetailLayout,
    TableV1
  },
  computed: {
    isEditAble() {
      return (
        checkRolePermission(PRICING_BASE_RATE.EDIT) &&
        this.detailData.rateVersionStatus.toLowerCase() === "draft"
      );
    }
  }
})
export default class Detail extends Vue {
  // misc variable
  columns = [
    {
      name: "Versi",
      key: "versi",
      styleHead: "w-2/12 text-left whitespace-no-wrap",
      render: (item: UpdateHistory) => {
        return `<span class='flex text-black-lp-300'>${item.versionName}</span>`;
      }
    },
    {
      name: "Tanggal",
      key: "tanggal",
      styleHead: "w-6/12 text-left whitespace-no-wrap",
      render: (item: UpdateHistory) => {
        return `<span class='flex text-black-lp-300'>${formatDate(
          item.createdAt
        )}</span>`;
      }
    },
    {
      name: "Oleh",
      key: "oleh",
      styleHead: "w-4/12 text-left whitespace-no-wrap",
      render: (item: UpdateHistory) => {
        return `<span class='flex text-black-lp-300'>${ellipsisString(
          item.accountName,
          15
        )}</span>`;
      }
    }
  ];

  get id(): number {
    return Number(this.$route.params.id);
  }

  convertWordingCurrency(str: string) {
    if(str.toLowerCase() === "idr") return "Rp (Indonesia)";
    if(str.toLowerCase() === "myr") return "RM (Malaysia)";
    return "-";
  }

  // Get Data
  created() {
    this.getDetail();
  }

  getDetail() {
    BaseRateController._onGetDetail(this.id);
  }

  // Detail Data
  get detailData(): RatesDetail {
    return BaseRateController.ratesDetail;
  }

  // Loading
  get isLoading() {
    return BaseRateController.isLoading;
  }

  // Data update history
  get updateHistoryList() {
    return this.detailData.rateVersiondUpdatedHistory.length > 0
      ? this.detailData.rateVersiondUpdatedHistory
      : [];
  }

  get styleStatusBaseRates() {
    switch (this.detailData.rateVersionStatus.toLowerCase()) {
      case "draft":
        return "bg-gray-lp-200 text-gray-lp-300";
      case "expired":
        return "bg-red-lp-600 text-red-lp-500";
      case "inactive":
        return "bg-red-lp-600 text-red-lp-500";
      case "for sales":
        return "bg-green-lp-300 text-green-lp-200";
      case "active":
        return "bg-green-lp-300 text-green-lp-200";
      default: {
        return "bg-green-lp-300 text-green-lp-200";
      }
    }
  }

  // route navigation
  goBack() {
    this.$router.push("/tarif/base-rate");
  }
  goEdit() {
    this.$router.push(`/tarif/base-rate/${this.$route.params.id}/edit`);
  }

  // Format Periode
  formatPeriode(start: string, end: string) {
    return `${dateToDateString(start)} - ${dateToDateString(end)}`;
  }

  // Format Date
  formatDateToString(date: string) {
    return formatDate(date);
  }

  // Handle Error
  get isError() {
    return BaseRateController.isError;
  }
  get errorCause() {
    return BaseRateController.isErrorCause;
  }
}
