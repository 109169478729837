
import { Options, Vue } from "vue-class-component";
import {
  checkRolePermission,
  formatDateWithoutTime
} from "@/app/infrastructures/misc/Utils";
import { PRICING_BASE_RATE } from "@/app/infrastructures/misc/RolePermission";
import { BaseRateController } from "@/app/ui/controllers/BaseRateController";
import EmptyState from "@/app/ui/components/empty-list/index.vue";

@Options({
  components: {
    EmptyState
  }
})
export default class BaseRate extends Vue {
  mounted() {
    BaseRateController.initBaseRates();
  }

  get getCurrentRoute() {
    return this.$route.meta.title;
  }

  get isDetailAble() {
    return checkRolePermission(PRICING_BASE_RATE.DETAIL);
  }

  onRequest() {
    BaseRateController.fetchBaseRates();
  }

  get firstRequest() {
    return BaseRateController.firstRequest;
  }

  get baseRates() {
    return BaseRateController.baseRatesData.data;
  }

  //Filter Search
  search = "";
  onSearch(value: string) {
    BaseRateController.searchAction(value);
  }
  get searchValue() {
    return BaseRateController.search;
  }
  clearSearch() {
    BaseRateController.clear();
    this.search = "";
  }

  statusData = [
    { name: "Semua Status", value: "" },
    { name: "Draft", value: "draft" },
    { name: "For Sales", value: "for-sales" },
    { name: "Active", value: "active" },
    { name: "Expired", value: "expired" }
  ];
  statusSelect = false;
  statusName = "";
  onOpenStatusSelect() {
    this.statusSelect = true;
  }
  onCloseStatusSelect() {
    this.statusSelect = false;
  }
  onSelectStatus(name: string, value: string) {
    BaseRateController.selectStatusAction({
      name: name,
      value: value
    });
    this.statusName = name;
    this.onCloseStatusSelect();
  }

  // Table
  columns = [
    {
      name: "ID Versi",
      key: "id",
      styleHead: "w-1/12 text-center whitespace-no-wrap",
      styleCustom: "text-red-lp-200 flex justify-center",
      render: (item: any) => {
        return `<span class='text-left text-red-lp-200 flex justify-center'>${item.id}</span>`;
      }
    },
    {
      name: "Nama Versi",
      styleHead: "w-3/12 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: any) => {
        return `<span class="overflow-ellipsis text-black-lp-300">${item.rateVersionName}</span>`;
      }
    },
    {
      name: "Total Leg",
      key: "totalLeg",
      styleHead: "w-1/12 text-left",
      styleBody: "text-left rounded py-0",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${item.totalLegRate}</div>`;
      }
    },
    {
      name: "Applied to",
      key: "appliedTo",
      styleHead: "w-1/12 text-left",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${
          item.appliedTo ? item.appliedTo + "clients" : "-"
        }</div>`;
      }
    },
    {
      name: "Rate Periode",
      key: "ratePeriode",
      styleHead: "w-3/12 text-left",
      styleBody: "text-left rounded py-0",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${formatDateWithoutTime(
          item.rateVersionStartDate
        )} - ${formatDateWithoutTime(item.rateVersionEndDate)}</div>`;
      }
    },
    {
      name: "Status",
      key: "status",
      styleHead: "w-1/12 text-left",
      styleBody: "",
      render: (item: any) => {
        return `
        <div class="flex">
      <div
        class="rounded-full px-2 py-0 text-center ${this.getClassBasedOnStatus(
          item.rateVersionStatus.toLowerCase()
        )}"
      ><span class="capitalize">${item.rateVersionStatus
        .toLowerCase()
        .replace("-", " ")}</span></div></div>`;
      }
    }
  ];
  getClassBasedOnStatus(status: string) {
    if (status === "active" || status === "for-sales")
      return "bg-green-lp-300 text-green-lp-200 text-red-lp-200";
    if (status === "draft") return "bg-gray-300 text-gray-lp-300";
    return "bg-red-300 text-red-lp-200";
  }
  onClickRow(item: any) {
    this.$router.push(`/tarif/base-rate/${item.id}`);
  }

  // Pagination Table
  get pagination() {
    return BaseRateController.baseRatesData.pagination;
  }

  //Loading
  get isLoading() {
    return BaseRateController.isLoading;
  }

  //Error
  get isError() {
    return BaseRateController.isError;
  }

  //Error Cause
  get errorCause() {
    return BaseRateController.isErrorCause;
  }
}
